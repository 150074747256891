import tw, { styled } from 'twin.macro';
import { Link } from 'gatsby'
import { Icon } from '../icon';
import { Container } from '../globalStyles';

export const HeaderContainer = tw.header`w-full sticky top-0 left-0 z-50 bg-black`;
export const Inner = tw(Container)`w-full flex items-center justify-between py-20 lg:px-40 xl:px-50 lg:py-40 relative`;
export const LogoLink = tw(Link)`block text-white min-w-148 lg:min-w-280 mr-20`;
export const MenuToggle = tw.button`flex items-center justify-end flex-wrap outline-none focus:outline-none mac:hidden `;
export const DeskMenu = tw.nav`hidden mac:flex w-full items-center justify-end`;

export const MobileMenu = styled.nav`
    ${tw`w-full h-screen mt-66 lg:mt-129 pb-66 lg:pb-129 bg-black absolute top-0 left-0 border-t border-white-30per`}
    ${({active}) => active ? tw`flex flex-col justify-between items-start` : tw`hidden`}
`;

export const MobileList = tw.div`p-20 block`;
export const MobileSocial = tw.div`p-20 flex items-center justify-start w-full border-t border-white-30per`;
export const MobileSocialLink = tw.a`w-56 ml-20 block`

export const MobileLink = tw(Link)`mb-25 font-extrabold text-24 block text-left`;

export const MenuLink = tw(Link)`w-full lg:w-auto font-thin font-sans text-white hover:text-orange-dark mr-44 text-16 hd:text-20 transition-all duration-500`

export const LogoIcon = tw(Icon)`block h-26 w-148 lg:h-49 lg:w-280`;
export const MenuIcon = styled(Icon)`
    ${tw`block text-white`}
    ${({active}) => active ? tw`w-24 h-24` : tw`w-18 h-12`}
`;
export const MenuLine = tw.div`w-1 h-30 hidden lg:block ml-40 mr-20 bg-white opacity-30`
export const SocialLink = tw.a`w-56 ml-20 block hidden lg:block`
export const SocialIcon = tw(Icon)`w-56 h-56 block`
