import { Link } from 'gatsby';
import tw, {styled} from 'twin.macro';
import Icon from './icon';

export const RTE = styled.div`
    h1 {
        ${tw`text-40`}
    }
    h2 {
        ${tw`text-32`}
    }
    h3 {
        ${tw`text-24`}
    }
    h4 {
        ${tw`text-20`}
    }
    h1, h2, h3, h4 {
        ${tw`font-sans text-white my-16 font-extrabold`}
    }
    ul {
        ${tw`text-left list-disc pl-8`}
    }
    p, li {
        ${tw`text-white mb-4 text-16 lg:text-20 mb-16 lg:mb-20 font-sans leading-150per`}
    }
    a {
        ${tw`underline inline-block text-orange hover:text-orange-dark`}
    }
    blockquote {
        ${tw`text-20 leading-28 lg:text-26 leading-40 pl-20 lg:pl-30 mb-20 lg:mb-30 relative`}
        &:before {
            content: '';
            background: linear-gradient(180deg, #3E599E 0%, #1ED8AC 100%);
            ${tw`w-3 h-full absolute top-0 left-0`}
        }
    }
    *:only-child {
        ${tw`mb-0`}
    }
`;

export const PageTitle = tw.h1`text-24 lg:text-60 font-extrabold text-white py-50 lg:py-80 text-left`
export const NewsTitle = tw.h1`text-24 lg:text-60 font-extrabold text-white py-50 lg:py-80 text-center`
export const ArticleIntro = tw.div`block pt-50 mb-20 pb-20 border-b border-white-30per lg:mb-40 lg:pb-40 lg:pt-80`
export const ArticleTitle = tw.h1`text-24 lg:text-60 lg:leading-55 font-extrabold text-white mb-10 lg:mb-20 text-left`
export const ArticleMeta = tw.div`flex items-center justify-start`;
export const MetaItem = tw.span`text-16 text-white opacity-70 lg:opacity-100 lg:text-20 mr-10 inline-block capitalize`
export const Breadcrumb = tw(Link)`flex items-center justify-start mb-12 lg:mb-20`;
export const Chevron = tw(Icon)`w-24 h-24 block mr-5`

export const Container = tw.section`w-full px-20 md:px-40 xl:px-140 max-w-hd text-white font-sans text-center mx-auto`;
export const DonateButton = tw.a`bg-orange-dark hover:bg-red hover:text-white text-black rounded-full py-8 px-20 hd:py-15 hd:px-30 text-16 hd:text-20 leading-24 lg:leading-32 transition-all duration-300`
export const Button = tw.a`bg-transparent border-white-30per border hover:border-white text-white rounded-full py-8 px-20 lg:py-15 lg:px-30 text-16 lg:text-20 leading-24 lg:leading-32 transition-all duration-300`
export const SidebarContainer = styled.section`
    ${tw`w-full md:px-40 xl:px-140 max-w-hd text-white font-sans text-left mx-auto flex flex-col-reverse lg:flex-row items-start justify-between`}
    section {
        ${tw`md:px-0`}
    }
`
export const SectionCol = tw.div`w-full block`;

export const Sidebar = tw.div`w-full lg:max-w-380 lg:ml-40 mb-20`
export const SidebarList = styled.div`
    ${tw`border-b border-white-30per p-20 md:px-0`}
    ${({open}) => open ? tw`block` : tw`hidden lg:block`}
`;
export const SidebarLink = styled(Link)`
    ${tw`mb-20 block`}
    &:last-child {
        ${tw`mb-0`}
    }
    &:hover {
        ${tw`text-orange-dark`}
    }
`;
export const SidebarTitle = tw.h2`text-white text-20 font-extrabold p-20 md:px-0 lg:pt-0 border-t border-b border-white-30per lg:border-t-0 flex items-center justify-between`
export const SidebarIcon = tw(Icon)`block lg:hidden`
export const FilterGroup = tw.div`flex items-center justify-center flex-wrap gap-10 lg:gap-20 mb-30 lg:mb-40`
export const FilterButton = styled.button`
    ${tw`capitalize border-white-30per border hover:border-white rounded-full py-8 px-20 lg:py-15 lg:px-30 text-16 lg:text-20 leading-24 lg:leading-32 transition-all duration-300`}
    ${({selected}) => selected ? tw`bg-white text-black` : tw`bg-transparent text-white`}
`;
